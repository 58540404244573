import { AscDesc, IIdName, IPagination } from "./common";

export enum UserRoles {
  Admin = 1,
  ContentManager,
}

export enum UserStatuses {
  Active = 1,
  Inactive = 2,
}

export interface IUser {
  id: number;
  dateCreated: string;
  dateUpdated: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  userRole: IIdName<UserRoles>;
  userStatus: IIdName<UserStatuses>;
}

export interface IUserCreatePayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  idRole: UserRoles;
  idStatus: UserStatuses;
}

export interface IUserCreateRequest extends IUserCreatePayload {}

export interface IUserUpdatePayload extends Partial<IUserCreatePayload> {}

export interface IUserUpdateRequest {
  id: number;
  body: IUserUpdatePayload;
}

export interface IUsers {
  pagination: IPagination;
  items: IUser[];
}

export interface IUserParams {
  page?: number;
  limit?: number;
  dateCreatedFrom?: string;
  dateCreatedTo?: string;
  dateUpdatedFrom?: string;
  dateUpdatedTo?: string;
  email?: string;
  name?: string;
  idStatus?: UserStatuses;
  idRole?: UserRoles;
  sortByIdStatus?: AscDesc;
  sortByIdRole?: AscDesc;
  sortByDateCreated?: AscDesc;
  sortByDateUpdated?: AscDesc;
  sortByEmail?: AscDesc;
  sortByName?: AscDesc;
}
