import { Button, message } from "antd";
import { PropsWithChildren, useState } from "react";
import { invalidateCacheTags } from "../../../helpers/invalidateCacheTags";

type ClearCacheButtonProps = PropsWithChildren<{
  tags: string[];
}>;

export const ClearCacheButton = ({ tags, children }: ClearCacheButtonProps) => {
  const [isLoading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    await invalidateCacheTags(tags);
    setLoading(false);
    message.success("Done!");
  };
  return (
    <Button
      type="primary"
      onClick={handleClick}
      loading={isLoading}
      disabled={isLoading}
    >
      {children || "Clear cache"}
    </Button>
  );
};
