import { cmsAPI } from "../cmsAPI";
import {
  IUser,
  IUserCreateRequest,
  IUserParams,
  IUsers,
  IUserUpdateRequest,
  UserRoles,
  UserStatuses,
} from "../../../models/user";
import { IIdName } from "../../../models/common";

export const cmsUsers = cmsAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<IUsers, IUserParams>({
      query: (params) => ({
        url: "/users",
        params,
      }),
      providesTags: ["User"],
    }),
    createUser: builder.mutation<IUser, IUserCreateRequest>({
      query: (data) => ({
        url: "/users",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation<IUser, IUserUpdateRequest>({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    getUser: builder.query<IUser, number>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      providesTags: ["User"],
    }),
    getUserStatuses: builder.query<IIdName<UserStatuses>[], void>({
      query: () => ({
        url: "/users/statuses",
      }),
    }),
    getUserRoles: builder.query<IIdName<UserRoles>[], void>({
      query: () => ({
        url: "/users/roles",
      }),
    }),
    getProfile: builder.query<IUser, void>({
      query: () => ({
        url: "/users/profile",
      }),
      providesTags: ["Profile"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetProfileQuery,
  useGetUserStatusesQuery,
  useGetUserRolesQuery,
} = cmsUsers;
