import { lazy, ReactElement, Suspense, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { DashboardLayout } from "./components/layout/DashboardLayout";
import { NotFoundPage } from "./components/pages/NotFoundPage";
import { useGetProfileQuery } from "./services/cmsAPI/endpoints/users";
import { UserRoles } from "./models/user";
import { SpinFullscreen } from "./components/layout/spin/SpinFullscreen";
import { omit } from "lodash";

const SamplesPage = lazy(() => import("./components/pages/SamplesPage"));
const SamplePage = lazy(() => import("./components/pages/SamplePage"));
const UsersPage = lazy(() => import("./components/pages/UsersPage"));
const AttributeSinglePage = lazy(
  () => import("./components/pages/AttributeSinglePage")
);
const AttributesPage = lazy(() => import("./components/pages/AttributesPage"));
const FilesPage = lazy(() => import("./components/pages/FilesPage"));
const RobotsPage = lazy(() => import("./components/pages/RobotsPage"));

const TranslationsPage = lazy(
  () => import("./components/pages/TranslationsPage")
);
const TranslationPage = lazy(
  () => import("./components/pages/TranslationPage")
);

const AssetsPage = lazy(() => import("./components/pages/AssetsPage"));

// TODO: Create pages skeletons with <Skeleton /> from Antd
// TODO: Create res handlers with <Result /> from Antd
// TODO: Create samples stats with <Progress /> from Antd
// TODO: Create Popconfirms with <Popconfirm /> from Antd
// TODO: Create notifications with <Notification /> from Antd

export const Dashboard = (): ReactElement => {
  const { data } = useGetProfileQuery();
  const isAdmin = data?.userRole?.id === UserRoles.Admin;

  const routes = useMemo(() => {
    return [
      {
        index: true,
        element: SamplesPage,
      },
      {
        path: "/samples/single/:id",
        element: SamplePage,
      },
      {
        path: "/attributes",
        element: AttributesPage,
      },
      {
        path: "/translations",
        element: TranslationsPage,
      },
      {
        path: "/translations/:id",
        element: TranslationPage,
      },
      {
        path: "/categories/:id",
        element: () => <AttributeSinglePage attribute="categories" />,
      },
      {
        path: "/subcategories/:id",
        element: () => <AttributeSinglePage attribute="subcategories" />,
      },
      {
        path: "/tags/:id",
        element: () => <AttributeSinglePage attribute="tags" />,
      },
      {
        path: "/essay-types/:id",
        element: () => <AttributeSinglePage attribute="essay-types" />,
      },
      {
        path: "/files",
        element: FilesPage,
      },
      {
        path: "/robots",
        element: RobotsPage,
      },
      {
        path: "/users",
        condition: isAdmin,
        element: UsersPage,
      },
      {
        path: "/assets",
        element: AssetsPage,
      },
    ]
      .filter((props) => ("condition" in props ? props.condition : true))
      .map((item) => omit(item, "condition"));
  }, [isAdmin]);

  return (
    <Routes>
      <Route path="/" element={<DashboardLayout />}>
        {routes.map(({ element: Element, ...props }, index) => (
          <Route
            {...props}
            key={String(props.path) + index}
            element={
              <Suspense fallback={<SpinFullscreen />}>
                <Element />
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
