import { ReactElement } from "react";
import cn from "classnames";
import { ReactComponent as LogoSVG } from "../../media/illustrations/logo.svg";
import { ReactComponent as LogoIconSVG } from "../../media/illustrations/logo-icon.svg";

import styles from "./Logo.module.scss";
import { Link } from "react-router-dom";

interface Props {
  className?: string;
  collapsed?: boolean;
}
export const Logo = ({ className, collapsed }: Props): ReactElement => {
  return (
    <div className={cn(styles.wrapper, className)}>
      <Link to="/">{collapsed ? <LogoIconSVG /> : <LogoSVG />}</Link>
    </div>
  );
};
