import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFilesParams} from "../../models/file";

interface IStateProps {
  params: IFilesParams
}

const initialState: IStateProps = {
  params: {
    page: 1,
    limit: 20,
  }
}

export const filesSlice = createSlice({
  name: 'Files',
  initialState,
  reducers: {
    setParams: (state, action:PayloadAction<IFilesParams>) => {
      state.params = {...state.params, ...action.payload}
    }
  }
})

export default filesSlice.reducer