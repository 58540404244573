import { ReactElement, useMemo } from "react";
import { Menu } from "antd";
import { menuList } from "./menuList";
import { Link, useLocation } from "react-router-dom";
import { useGetProfileQuery } from "../../../services/cmsAPI/endpoints/users";
import * as React from "react";

export default function AntdMenu(): ReactElement {
  const { data } = useGetProfileQuery();

  const location = useLocation();

  const items = useMemo(() => {
    return menuList
      .filter(
        ({ roles }) => !(roles && !roles.includes(data?.userRole?.id as number))
      )
      .map(({ url, heading, icons }) => ({
        icon: (
          <>
            {icons.default}
            <span className="ant-menu-title-content">
              <Link to={url}>{heading}</Link>
            </span>
          </>
        ),
        children: null,
        key: url,
      }));
  }, [data?.userRole?.id]);

  return (
    <Menu mode="inline" selectedKeys={[location.pathname]} items={items} />
  );
}
