import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AttributeType,
  IAttributesParams,
  IAttributesStatus,
} from "../../models/attributes";
import { IAttributesContentFormValues } from "../../components/attributes/single/AttributesContentForm";

const defaultParams: IAttributesParams = {
  page: 1,
  limit: 20,
  idStatus: [IAttributesStatus.Active],
};

interface IAttributeSettings {
  params: IAttributesParams;
}

// TODO: think about this structure
interface IStateProps {
  activeTab: AttributeType;
  params: {
    categories: IAttributeSettings;
    subcategories: IAttributeSettings;
    tags: IAttributeSettings;
    "essay-types": IAttributeSettings;
  };
  createValues: {
    form: IAttributesContentFormValues;
    content: string;
  };
}

const initialState: IStateProps = {
  activeTab: "categories",
  params: {
    categories: {
      params: defaultParams,
    },
    subcategories: {
      params: defaultParams,
    },
    tags: {
      params: defaultParams,
    },
    "essay-types": {
      params: defaultParams,
    },
  },
  createValues: {
    form: {
      attribute: "categories",
      title: "",
      slug: "",
      description: "",
      idCategory: undefined,
      metaTitle: "",
      metaDescription: "",
    },
    content: "",
  },
};

export const AttributesSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<AttributeType>) => {
      state.activeTab = action.payload;
    },
    setParams: (
      state,
      action: PayloadAction<{
        attributeType: AttributeType;
        params: IAttributesParams;
      }>
    ) => {
      const { attributeType, params } = action.payload;
      state.params[attributeType].params = params;
    },
    restoreCreateValues: (state) => {
      state.createValues = initialState.createValues;
    },
    setCreateFormValues: (
      state,
      action: PayloadAction<IAttributesContentFormValues>
    ) => {
      state.createValues.form = action.payload;
    },
    setCreateContent: (state, action: PayloadAction<string>) => {
      state.createValues.content = action.payload;
    },
  },
});

export default AttributesSlice.reducer;
