import { Tag } from "antd";
import cn from "classnames";
import { ReactElement } from "react";
import { SamplesStatusColor } from "../../../models/common";
import styles from "./StatItem.module.scss";

interface Props {
  id: number;
  name: string;
  total: number;
  collapsed: boolean | undefined;
}

export const StatItem = ({ id, name, total, collapsed }: Props): ReactElement => {
  return (
    <div className={styles.item}>
      {!collapsed && (
        <div className={styles.main}>
          <div className={cn(styles.status, styles[SamplesStatusColor[id]])} />
          <div className={styles.heading}>{name}:</div>
        </div>
      )}
      <div className={cn(styles.count, styles[SamplesStatusColor[id]])}>
        <Tag color={SamplesStatusColor[id]}>{total}</Tag>
      </div>
    </div>
  );
};
