import { ReactElement } from "react";
import { ReactComponent as LoginBro } from "../../media/illustrations/login-bro.svg";

import styles from "./AuthPage.module.scss";
import { AuthForm } from "./AuthForm";
import { Logo } from "../logo/Logo";

export const AuthPage = (): ReactElement => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.top}>
          <Logo />
          <div className={styles.divider} />
          <div className={styles.heading}>Dashboard</div>
        </div>
        <div className={styles.form}>
          <AuthForm />
        </div>
      </div>
      <div className={styles.right}>
        <LoginBro />
      </div>
    </div>
  );
};
