import { cmsAPI } from "../cmsAPI";
import { IAuthJWTTokens, ISignIn } from "../../../models/auth";

export const cmsAuth = cmsAPI.injectEndpoints({
  endpoints: (builder) => ({
    jwtSignIn: builder.mutation<IAuthJWTTokens, ISignIn>({
      query: (credentials) => ({
        url: "auth/jwt/sign-in",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const { useJwtSignInMutation } = cmsAuth;
