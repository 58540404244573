import { ReactElement, useEffect } from "react";
import { useAppDispatch } from "../../hooks/redux";
import { setGlobalPageTitle } from "../../store/global/GlobalActions";

interface Props {
  title: string;
  children: ReactElement;
}

export const Page = ({ title, children }: Props): ReactElement => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setGlobalPageTitle(title));
  }, [dispatch, title]);

  useEffect(() => {
    document.title = `${title || "Untitled"} | Samples CMS`;
  }, [title]);

  return children;
};
