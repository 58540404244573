import { SITE_URL } from "../config/const";
import axios from "axios";

export const invalidateCacheTags = async (
  tags: (string | { tag: string; slug: string })[]
) => {
  if (!tags.length) return;
  try {
    await axios.post(`${SITE_URL}/api/revalidate`, {
      tags: tags.map((item) => {
        if (typeof item === "string") return item;
        return item.tag + item.slug;
      }),
    });
  } catch (err) {}
};
