import { Nullable } from "../../models/common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthJWTTokens } from "../../models/auth";

interface AuthState {
  isLoggedIn: boolean;
  tokens: IAuthJWTTokens;
  tokenExp: Nullable<number>;
}

const initialState: AuthState = {
  isLoggedIn: false,
  tokens: {
    accessToken: null,
    refreshToken: null,
  },
  tokenExp: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<IAuthJWTTokens>) => {
      state.tokens = action.payload;
      state.isLoggedIn = true;
    },
    swapTokens: (state) => {
      console.log("swap tokens");
      state.tokens.accessToken = state.tokens.refreshToken;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.tokens = {
        accessToken: null,
        refreshToken: null,
      };
    },
  },
});

export default authSlice.reducer;
