import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITranslationsSearchParams } from "../../models/translations";

interface IStateParams {
  params: ITranslationsSearchParams;
}

const initialState: IStateParams = {
  params: {
    page: 1,
    limit: 100,
    sortByDateAdded: "desc",
  },
};

export const translationsSlice = createSlice({
  name: "Translations",
  initialState,
  reducers: {
    setParams: (state, action: PayloadAction<ITranslationsSearchParams>) => {
      state.params = { ...state.params, ...action.payload };
    },
  },
});

export default translationsSlice.reducer;
