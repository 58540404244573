export const CMS_API_URL = process.env.REACT_APP_CMS_API_URL;
export const SITE_URL = process.env.REACT_APP_SITE_URL;

export const IMAGE_EXTENSIONS = [".jpeg", ".png", ".gif", ".jpg", ".webp"];

export const ALLOWED_EXTENSIONS = [
  ...IMAGE_EXTENSIONS,
  ".txt",
  ".zip",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".rtf",
  ".ppt",
  ".pptx",
  ".csv",
  ".pdf",
  ".odt",
  ".html",
];

export const UPLOAD_ACCEPT_FILES = ALLOWED_EXTENSIONS.join(",");

export const UPLOAD_ACCEPT_IMAGES = IMAGE_EXTENSIONS.join(",");
