import { IAuthJWTTokens } from "../../models/auth";
import { AppDispatch } from "../store";
import { authSlice } from "./AuthSlice";

const { setTokens, swapTokens, logout } = authSlice.actions;

export const authSetTokens =
  (tokens: IAuthJWTTokens) => (dispatch: AppDispatch) => {
    dispatch(setTokens(tokens));
  };

export const authSwapTokens = () => (dispatch: AppDispatch) =>
  dispatch(swapTokens());

export const authLogout = () => (dispatch: AppDispatch) => {
  dispatch(logout());
};
