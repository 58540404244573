import { cmsAPI } from "../cmsAPI";
import {
  IAcademicLevel,
  IPaperFormat,
  IPaperType,
  ISampleExtended,
  ISampleParams,
  ISamples,
  ISampleStatus,
  ISamplesTotal,
  ISampleTag,
  ISampleUpdate,
  ITypeOfWork,
  SampleTagStatuses,
} from "../../../models/samples";

const cmsSamples = cmsAPI.injectEndpoints({
  endpoints: (builder) => ({
    getSamples: builder.query<ISamples, ISampleParams>({
      query: (params) => ({
        url: `samples`,
        params,
      }),
      providesTags: ["Sample"],
    }),
    getSamplesSimple: builder.query<ISamples, ISampleParams>({
      query: (params) => ({
        url: `samples/simple`,
        params,
      }),
      providesTags: ["Sample"],
    }),
    getSamplesTotal: builder.query<ISamplesTotal, ISampleParams>({
      query: (params) => ({
        url: `samples/total`,
        params,
      }),
      providesTags: ["Sample"],
    }),
    getSample: builder.query<ISampleExtended, string>({
      query: (id) => ({
        url: `samples/${id}`,
      }),
      providesTags: ["Sample"],
    }),
    updateSample: builder.mutation<
      ISampleExtended,
      { body: ISampleUpdate; id: number }
    >({
      query: ({ body, id }) => ({
        url: `samples/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Sample"],
    }),
    getSampleTags: builder.query<ISampleTag[], string>({
      query: (id) => ({
        url: `samples/${id}/tags`,
      }),
    }),
    setSampleTags: builder.mutation<
      null,
      { params: { tags: number[] }; id: number }
    >({
      query: ({ params, id }) => ({
        url: `samples/${id}/tags`,
        method: "POST",
        params,
      }),
    }),
    updateSampleTagStatus: builder.mutation<
      null,
      { id: number; idStatus: SampleTagStatuses }
    >({
      query: ({ id, idStatus }) => ({
        url: `samples/tags/${id}/statuses/${idStatus}`,
        method: "PUT",
      }),
    }),
    reprocessSample: builder.mutation<null, { id: number }>({
      query: ({ id }) => ({
        url: `samples/${id}/reprocess`,
        method: "PUT",
      }),
      invalidatesTags: ["Sample"],
    }),
    getStatuses: builder.query<ISampleStatus[], string>({
      query: () => ({
        url: "samples/statuses",
      }),
    }),
    getPaperFormats: builder.query<IPaperFormat[], string>({
      query: () => ({
        url: "samples/paper-formats",
      }),
    }),
    getPaperTypes: builder.query<IPaperType[], string>({
      query: () => ({
        url: "samples/paper-types",
      }),
    }),
    getAcademicLevels: builder.query<IAcademicLevel[], string>({
      query: () => ({
        url: "samples/academic-levels",
      }),
    }),
    getTypeOfWorks: builder.query<ITypeOfWork[], string>({
      query: () => ({
        url: "samples/type-of-works",
      }),
    }),
    getStats: builder.query({
      query: () => ({
        url: "samples/stats",
      }),
      providesTags: ["Sample"],
    }),
    getCategoryStats: builder.query({
      query: (id) => ({
        url: `samples/stats/categories/${id}/subcategories`,
      }),
    }),
  }),
});

export const {
  useGetSamplesQuery,
  useGetSamplesSimpleQuery,
  useGetSamplesTotalQuery,
  useGetSampleQuery,
  useUpdateSampleMutation,
  useGetSampleTagsQuery,
  useSetSampleTagsMutation,
  useUpdateSampleTagStatusMutation,
  useGetStatusesQuery,
  useGetPaperFormatsQuery,
  useGetPaperTypesQuery,
  useGetAcademicLevelsQuery,
  useGetTypeOfWorksQuery,
  useGetStatsQuery,
  useGetCategoryStatsQuery,
  useReprocessSampleMutation,
} = cmsSamples;
