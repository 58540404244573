import {
  CreditCardFilled,
  CreditCardOutlined,
  EditFilled,
  EditOutlined,
  FileFilled,
  FileImageOutlined,
  FileOutlined,
  RobotOutlined,
  TranslationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { UserRoles } from "../../../models/user";

export const menuList = [
  {
    id: 0,
    heading: "Samples",
    url: "/",
    icons: {
      default: <CreditCardOutlined />,
      active: <CreditCardFilled />,
    },
  },
  {
    id: 1,
    heading: "Attributes",
    url: "/attributes",
    icons: {
      default: <EditOutlined />,
      active: <EditFilled />,
    },
  },
  {
    id: 2,
    heading: "Translations",
    url: "/translations",
    icons: {
      default: <TranslationOutlined />,
      active: <TranslationOutlined />,
    },
  },
  {
    id: 3,
    heading: "Files",
    url: "/files",
    icons: {
      default: <FileOutlined />,
      active: <FileFilled />,
    },
  },
  {
    id: 6,
    heading: "Users",
    url: "/users",
    roles: [UserRoles.Admin],
    icons: {
      default: <UserOutlined />,
      active: <UserOutlined />,
    },
  },
  {
    id: 6,
    heading: "Assets",
    url: "/assets",
    icons: {
      default: <FileImageOutlined />,
      active: <FileImageOutlined />,
    },
  },
  // {
  //   id: 4,
  //   heading: "Clients",
  //   url: "/clients",
  //   icons: {
  //     default: <TeamOutlined />,
  //     active: <TeamOutlined />,
  //   },
  // },
  // {
  //   id: 5,
  //   heading: 'Client samples',
  //   url: '/client-samples',
  //   icons: {
  //     default: <AuditOutlined/>,
  //     active: <AuditOutlined/>,
  //   },
  // },
  {
    id: 7,
    heading: "Robots",
    url: "/robots",
    icons: {
      default: <RobotOutlined />,
      active: <RobotOutlined />,
    },
  },
];
