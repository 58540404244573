import cn from "classnames";
import { ReactElement } from "react";
import { useOnLocationChange } from "../../../hooks/useOnLocationChange";
import { useGetStatsQuery } from "../../../services/cmsAPI/endpoints/samples";
import { SpinWrapper } from "../../layout/spin/SpinWrapper";
import styles from "./SamplesStats.module.scss";
import { StatItem } from "./StatItem";
import {ISampleStatusStats} from "../../../models/samples";

interface Props {
  className?: string;
  collapsed?: boolean | undefined;
}

export const SamplesStats = ({ className, collapsed }: Props): ReactElement => {
  const { data, isLoading, refetch } = useGetStatsQuery("");
  useOnLocationChange(refetch);
  return (
    <div className={cn(styles.wrapper, className)}>
      {!!data && !isLoading ? (
        (data as ISampleStatusStats[]).map((item) => (
          <StatItem key={item.id} collapsed={collapsed} {...item} />
        ))
      ) : (
        <SpinWrapper />
      )}
    </div>
  );
};
