// Attributes

import { IContent, IIdName, IMeta, IPage } from "./common";

export type AttributeType =
  | "categories"
  | "subcategories"
  | "tags"
  | "essay-types";

export enum IAttributesStatus {
  Active = 1,
  Inactive,
}

export interface IAttributesParams {
  page: number;
  limit: number;
  name?: string;
  slug?: string;
  content?: string;
  idStatus?: IAttributesStatus[];
  idCategory?: number[];
}

export interface IBaseAttribute extends IIdName<number> {
  dateCreated: string;
  dateUpdated: string;
  slug: string;
  meta: IMeta;
  content: IContent;
  page: IPage;
  status: IIdName<IAttributesStatus>;
}
export interface IBaseCategory extends IBaseAttribute {}
export interface IBaseSubcategory extends IBaseCategory {
  category: IBaseCategory;
}
export interface IExtendedSubcategory extends IBaseCategory {
  category: IBaseCategory;
}
export interface IBaseEssayType extends IBaseAttribute {}
export interface IBaseTag extends IBaseAttribute {}

// Attributes create

export interface ICreateAttribute {
  slug: string;
  meta: IMeta;
  content: IContent;
  name: string;
}
export interface ICreateCategory extends ICreateAttribute {}
export interface ICreateSubcategory extends ICreateAttribute {}
export interface ICreateTag extends ICreateAttribute {}
export interface ICreateEssayType extends ICreateAttribute {}

export type IAllAttributes =
  | IBaseCategory
  | IBaseSubcategory
  | IBaseTag
  | IBaseEssayType;
