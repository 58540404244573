import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserParams } from "../../models/user";

interface IStateProps {
  params: IUserParams;
}

const initialState: IStateProps = {
  params: {
    page: 1,
    limit: 20,
    sortByDateCreated: "desc",
  },
};

export const usersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    setParams: (state, action: PayloadAction<IUserParams>) => {
      state.params = { ...state.params, ...action.payload };
    },
  },
});

export default usersSlice.reducer;
