import { Spin } from "antd";
import { ReactElement } from "react";

import styles from "./SpinWrapper.module.scss";

interface Props {
  tip?: string;
  size?: "small" | "default" | "large" | undefined;
}

export const SpinWrapper = ({ tip, size }: Props): ReactElement => {
  return (
    <div className={styles.wrapper}>
      <Spin size={size} tip={tip} />
    </div>
  );
};
