import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  pageTitle: "Untitled",
  isMenuCollapsed: true,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
    setMenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isMenuCollapsed = action.payload;
    },
  },
});

export const menuCollapsedSelector = ({ global }: RootState) =>
  global.isMenuCollapsed;

export default globalSlice.reducer;
