export type Nullable<T> = T | null | undefined;

export interface IIdName<T extends number> {
  id: T;
  name: string;
}

export interface IPagination {
  limit: number;
  page: number;
  total: number;
  pages: number;
}

export interface IOption {
  value: string | number;
  label: any;
}

export interface IMeta {
  title: string;
  description: string;
}

export interface IContent {
  description?: string;
  content?: string;
  h1?: string;
  faqTitle?: string;
  faq?: IFaq[] | undefined;
}

export interface ITableOfContents {
  heading: string;
  anchor: string;
}
export interface IFaq {
  question: string;
  answer: string;
}

export interface IPage {
  id: number;
  dateUpdated: string;
  slug: string;
  pageType: IIdName<number>;
}

// Stats

export enum SamplesStatusColor {
  magenta = 1,
  red = 2,
  gold = 3,
  green = 4,
  lime = 5,
  default = 6,
}

export enum AttributeStatusColor {
  green = 1,
  default = 2,
}

export enum FilesStatusesColor {
  magenta = 1,
  gold = 2,
  green = 3,
  red = 4,
}

export enum UserStatusesColor {
  green = 1,
  red = 2,
}

export enum TranslationStatusesColor {
  gold = 1,
  green = 2,
}

export type AscDesc = "asc" | "desc";

export interface IPatch<T> {
  id: number;
  body: T;
}
