import {
  ArrowLeftOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useOnLocationChange } from "../../../hooks/useOnLocationChange";
import { useGetProfileQuery } from "../../../services/cmsAPI/endpoints/users";
import { authLogout } from "../../../store/auth/AuthActions";
import { SpinWrapper } from "../spin/SpinWrapper";

import cn from "classnames";

import styles from "./Header.module.scss";
import { Tag } from "antd";
import { ClearCacheButton } from "./ClearCacheButton";
import {
  TAG_CATEGORIES_SUBCATEGORIES,
  TAG_ESSAY_TYPES,
  TAG_TOPICS,
} from "../../../config/cacheTags";

interface Props {
  className?: string;
}

// TODO: Refactor with <PageHeader /> Antd component

export const Header = ({ className }: Props): ReactElement => {
  const { data: profile, isLoading, refetch } = useGetProfileQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pageTitle } = useAppSelector((state) => state.global);

  useOnLocationChange(refetch);

  return (
    <div className={cn(styles.wrapper, className)}>
      <>
        <div className={styles.heading}>
          <ArrowLeftOutlined
            className={styles.backBtn}
            onClick={() => navigate(-1)}
          />
          <h1>{pageTitle}</h1>
        </div>
        <div className={styles.controls}>
          {isLoading ? (
            <SpinWrapper />
          ) : (
            <>
              <ClearCacheButton
                tags={[
                  TAG_CATEGORIES_SUBCATEGORIES,
                  TAG_ESSAY_TYPES,
                  TAG_TOPICS,
                ]}
              >
                Purge all cache
              </ClearCacheButton>
              <div className={styles.user}>
                <div className={styles.userIcon}>
                  <UserOutlined />
                </div>
                <div>
                  <div>
                    <Tag>{profile?.userRole?.name}</Tag>
                    {profile?.email || "User"}{" "}
                  </div>
                </div>
              </div>

              <div
                className={styles.logout}
                onClick={() => dispatch(authLogout())}
              >
                <LogoutOutlined /> Logout
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
};
