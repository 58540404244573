import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/AuthSlice";
import globalReducer from "./global/GlobalSlice";
import samplesReducer from "./samples/SamplesSlice";
import translationsReducer from "./translations/TranslationsSlice";
import attributesReducer from "./attributes/AttributesSlice";
import filesReducer from "./files/FilesSlice";
import usersReducer from "./users/UsersSlice";
import assetsReducer from "./assets/AssetsSlice";
import { cmsAPI } from "../services/cmsAPI/cmsAPI";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  samples: samplesReducer,
  attributes: attributesReducer,
  files: filesReducer,
  users: usersReducer,
  translations: translationsReducer,
  assets: assetsReducer,
  [cmsAPI.reducerPath]: cmsAPI.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["cmsAPI"],
};

export const setupStore = () => {
  return configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([cmsAPI.middleware]),
  });
};

export const mainStore = setupStore();

export const persistor = persistStore(mainStore);

// setupListeners(mainStore.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppGetState = () => RootState;
