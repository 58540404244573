import { useEffect } from "react";
import { AuthPage } from "./components/auth/AuthPage";
import { Dashboard } from "./Dashboard";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { authSetTokens } from "./store/auth/AuthActions";
import "./styles/index.scss";
import { useGetProfileQuery } from "./services/cmsAPI/endpoints/users";
import { SpinWrapper } from "./components/layout/spin/SpinWrapper";

function App() {
  const dispatch = useAppDispatch();
  const { isLoggedIn, tokens } = useAppSelector((state) => state.auth);

  const { data } = useGetProfileQuery(undefined, { skip: !isLoggedIn });

  useEffect(() => {
    if (tokens.accessToken && tokens.refreshToken)
      dispatch(authSetTokens(tokens));
  }, [dispatch, tokens]);

  return (
    <div className="App">
      {isLoggedIn ? data ? <Dashboard /> : <SpinWrapper /> : <AuthPage />}
    </div>
  );
}

export default App;
