import {ISampleParams} from "../../models/samples";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface IStateParams {
  params: ISampleParams
}

const initialState: IStateParams = {
  params: {
    page: 1,
    limit: 20
  }
}

export const samplesSlice = createSlice({
  name: 'Samples',
  initialState,
  reducers: {
    setParams: (state, action: PayloadAction<ISampleParams>) => {
      state.params = {...state.params, ...action.payload}
    }
  }
})

export default samplesSlice.reducer