import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAssetsSearchParams } from "../../models/assets";

interface IStateParams {
  params: IAssetsSearchParams;
}

const initialState: IStateParams = {
  params: {
    page: 1,
    limit: 20,
    sortByDateAdded: "desc",
  },
};

export const assetsSlice = createSlice({
  name: "Assets",
  initialState,
  reducers: {
    setParams: (state, action: PayloadAction<IAssetsSearchParams>) => {
      state.params = { ...state.params, ...action.payload };
    },
  },
});

export default assetsSlice.reducer;
