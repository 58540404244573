import { AppDispatch } from "../store";
import { globalSlice } from "./GlobalSlice";

const { setPageTitle, setMenuCollapsed } = globalSlice.actions;

export const setGlobalPageTitle = (title: string) => (dispatch: AppDispatch) =>
  dispatch(setPageTitle(title));

export const globalSetMenuCollapsed =
  (boolean: boolean) => (dispatch: AppDispatch) => {
    dispatch(setMenuCollapsed(boolean));
  };
