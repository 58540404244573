import { Button } from "antd";
import { ReactElement } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as UFOCow } from "../../media/illustrations/404.svg";
import { Page } from "../layout/Page";

import styles from "./ErrorPage.module.scss";

export const NotFoundPage = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <Page title="404">
      <div className={styles.wrapper}>
        <UFOCow />
        <Button
          className={styles.button}
          size="large"
          type="primary"
          onClick={() => navigate("/", { replace: true })}
        >
          Go home
        </Button>
      </div>
    </Page>
  );
};
