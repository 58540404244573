import {ReactElement} from "react";
import styles from "./DashboardLayout.module.scss";
import {Outlet} from "react-router-dom";
import {Header} from "./header/Header";
import {Layout} from "antd";
import {Logo} from "../logo/Logo";
import AntdMenu from "./mainMenu/AntdMenu";
import {SamplesStats} from "../samples/samplesStats/SamplesStats";
import {useMenuCollapsed} from "../../hooks/layout/useMenuCollapsed";
import {ReactComponent as StatsBro} from "../../media/illustrations/stats-bro.svg";

export const DashboardLayout = (): ReactElement => {
  const {Header: AntdHeader, Content, Sider} = Layout;
  const {isMenuCollapsed, toggleCollapse} = useMenuCollapsed();

  return (
    <Layout className={styles.layout}>
      <Sider
          collapsible
          collapsed={isMenuCollapsed}
          onCollapse={toggleCollapse}
          theme="light"
          width={230}
          collapsedWidth={100}
          className={styles.sider}
      >
        <div className={styles.siderChildrens}>
          <Logo className={styles.logo} collapsed={isMenuCollapsed}/>
          <AntdMenu/>
          <SamplesStats className={styles.stats} collapsed={isMenuCollapsed}/>
          {!isMenuCollapsed && (
              <div className={styles.bro}>
                <StatsBro/>
              </div>
          )}
        </div>
      </Sider>
      <Layout className={styles.layoutContent}>
        <AntdHeader className={styles.header}>
          <Header/>
        </AntdHeader>
        <Content className={styles.contentWrapper}>
          <div className={styles.content}>
            <Outlet/>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
