import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { message } from "antd";
import { CMS_API_URL } from "../../config/const";
import { IAuthJWTTokens } from "../../models/auth";
import {
  authLogout,
  authSetTokens,
  authSwapTokens,
} from "../../store/auth/AuthActions";
import { RootState } from "../../store/store";

import qs from "query-string";
import { upperFirst } from "lodash";

const baseQuery = fetchBaseQuery({
  baseUrl: CMS_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth.tokens;
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
  paramsSerializer: ({ _sortBy, ...params }) => {
    /// multi-layer-sorting
    let primaryQs = qs.stringify(params);

    if (Array.isArray(_sortBy)) {
      _sortBy.forEach((item) => {
        if (typeof item === "object" && "name" in item && "value" in item) {
          primaryQs +=
            (primaryQs ? "&" : "") +
            `sortBy${upperFirst(item.name)}=${item.value}`;
        }
      });
    }

    return primaryQs;
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result: any = await baseQuery(args, api, extraOptions);

  const isSlugChecker = api.endpoint === "checkSlug";

  if (result.error && result.error.status !== 401 && !isSlugChecker) {
    if (result.error.data) {
      result.error.data.errors.map((error: string) => message.error(error));
    } else {
      message.error("Error! Something went wrong");
    }
  }

  if (result.error && result.error.status === 401) {
    api.dispatch(authSwapTokens());

    const tokensResponse = await baseQuery(
      { url: "auth/jwt/refresh" },
      api,
      extraOptions
    );

    if (tokensResponse.data) {
      api.dispatch(authSetTokens(tokensResponse.data as IAuthJWTTokens));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(authLogout());
    }
  }
  return result;
};

export const cmsAPI = createApi({
  reducerPath: "cmsAPI",
  baseQuery: baseQueryWithReAuth,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  tagTypes: [
    "Sample",
    "Category",
    "Subcategory",
    "Tag",
    "Essay Type",
    "User",
    "Profile",
    "Translation",
    "Asset",
  ],
  endpoints: () => ({}),
});
