import {ReactElement} from 'react';
import {Form, Input, Button, message} from 'antd';
import {UserOutlined, KeyOutlined} from '@ant-design/icons';

import styles from './AuthForm.module.scss';
import {useAppDispatch} from '../../hooks/redux';
import {authSetTokens} from '../../store/auth/AuthActions';
import {useJwtSignInMutation} from '../../services/cmsAPI/endpoints/auth';
import {ISignIn} from '../../models/auth';

export const AuthForm = (): ReactElement => {
  const dispatch = useAppDispatch();

  const initialFormValues = {
    email: '',
    password: '',
  };

  const [jwtSignIn, result] = useJwtSignInMutation();

  const onFinish = (values: ISignIn) => {
    const credentials = {
      email: values.email,
      password: values.password,
    };
    jwtSignIn(credentials)
        .unwrap()
        .then((tokens) => dispatch(authSetTokens(tokens)))
        .catch((e) => {
          message.error('Error! Something went wrong');
          console.log(e);
        });
  };

  return (
    <Form size="large" name="email" initialValues={initialFormValues} onFinish={onFinish}>
      <Form.Item
        name="email"
        rules={[{ required: true, type: "email", message: "Please input valid Email" }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
        <Input.Password prefix={<KeyOutlined />} placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" loading={result.isLoading} htmlType="submit" className={styles.button}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};
