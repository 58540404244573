import { useEffect } from "react";
import { globalSetMenuCollapsed } from "../../store/global/GlobalActions";
import { useAppDispatch, useAppSelector } from "../redux";
import { menuCollapsedSelector } from "../../store/global/GlobalSlice";

export const useMenuCollapsed = () => {
  const dispatch = useAppDispatch();

  const menuCollapsed = useAppSelector(menuCollapsedSelector);
  useEffect(() => {
    if (menuCollapsed !== null) dispatch(globalSetMenuCollapsed(menuCollapsed));
  }, [dispatch, menuCollapsed]);

  const toggleCollapse = () =>
    dispatch(globalSetMenuCollapsed(!isMenuCollapsed));

  const { isMenuCollapsed } = useAppSelector((state) => state.global);

  return {
    isMenuCollapsed,
    toggleCollapse,
  };
};
